.contact-row {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
}

@media (min-width: 768px) {
    .contact-row {
        flex-direction: row;
        justify-content: center;
    }
}